<template>
  <div class="body">
    <van-swipe class="my-swipe" :autoplay="3000" :height="200" indicator-color="white">
      <van-swipe-item v-for="(item, index) in images" :key="index" class="swipe-img">
        <img :src="item.url" @click="openUrl(item.webUrl)" />
      </van-swipe-item>
    </van-swipe>
    <van-grid :column-num="3" style="margin-bottom:40px;">
      <van-grid-item
        v-for="(item) in (( user && user.menuList) || []) "
        :key="item.id"
        :text="item.functionName"
        :url="item.webUrl.startsWith('http')?item.webUrl:''"
        :to="item.webUrl.startsWith('http')?'':item.webUrl"
      >
        <template #icon>
          <van-image width="2rem" height="2rem" :src="item.url" />
        </template>
      </van-grid-item>
    </van-grid>
    <Footer page="index"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { getSearchParams, env, getLoginInfo } from "../../utils/index";
import { Toast } from 'vant';
export default {
  data() {
    return {
      user: null,
      images: [],
      routers: [],
    };
  },
  components: {
    Footer,
  },
  methods: {
    openUrl(webUrl) {
      if (webUrl != null && webUrl != "") {
        if (
          webUrl.indexOf("http://") == -1 &&
          webUrl.indexOf("https://") == -1
        ) {
          window.location.href = "http://" + webUrl;
        } else {
          window.location.href = webUrl;
        }
      }
    },
    getBanners() {
      this.$axios
        .get("/banner-pictures/getBannerUrl")
        .then((res) => {
          if (res.code == "ok") {
            this.images = res.data.banner;
          } else {
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    checkLogin() {
      getLoginInfo((user) => {
        if (!user) {
          Toast.fail("服务器连接失败");
          return;
        }
        this.user = this.$user;

      });
    },
  },
  created() {
    console.log(' index created >>> ');
  },
  mounted() {
    console.log(' index mounted >>> ');
    this.getBanners();
    this.checkLogin();
  },
};
</script>

<style lang="less" >
.swipe-img {
  img {
    width: 100%;
    height: 100%;
  }
}

.body {
  height: calc(100vh - 50px);
  position: relative;
}

.tip {
  position: absolute;
  width: 100%;
  bottom: 5px;
  font-size: 14px;
  color: #8f8f8f;
  margin-top: 20px;
  text-align: center;
  line-height: 30px;
}
.van-grid-item__text {
  font-size: 0.37rem;
  color: #000;
}
</style>
